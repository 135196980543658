.input_body {
    margin-top: 40px;
    position: relative;
}

@media(max-width: 768px) {
    .input_body {
        margin-top: 25px;
    }
}

.input_area {
    padding: 20px 90px 20px 40px;
    width: 100%;
    font-size: 20px;
    line-height: 150%;
    border-radius: 20px;
    background: #FFFFFF;
}

@media(max-width: 768px) {
    .input_area {
        padding: 16px 115px 16px 20px;
        font-size: 16px;
        border-radius: 12px;
        text-align:  left !important;
    }
}

.send {
    position: absolute;
    right: 4px;
    width: 64px;
    height: 64px;
    top: 3px;
    border-radius: 20px;
}

@media(max-width: 768px) {
    .send {
        width: 50px;
        height: 50px;
        border-radius: 12px;
    }
}

.mic_container {
    position: absolute;
    right: 4px;
    top: 3px;
    color: #000000;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    line-height: 150%;
}




@media(max-width: 768px) {
    .mic {
        width: 50px;
        height: 50px;
        border-radius: 12px;
        right: 57px;
    }
}

.time {
    width: 70px;
    display: flex;
    align-items: center;
    gap: 10px;

}

.circle {
    width: 10px;
    height: 10px;
    background: #BE0000;
    border-radius: 50%;
    animation: circle 1s linear infinite;
    box-shadow: 0px 0px 5px 1px #BE0000;
}

@keyframes circle {

    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}

.mic, .send {
    cursor: pointer;

    &:hover {
        fill: #14A1ED;
    }

    &:active {
        fill: #005E7B;
    }
}