.button {
    width: 280px;
    height: 80px;
    max-width: 100%;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    text-transform: capitalize;
    color: #FFFFFF;
    padding: 10px 40px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    transition: .3s;
    &:hover {
        background: rgba(1, 193, 254, 0.4);
    }
    &:active {
        background: rgba(0, 94, 123, 0.4);
    }
}

@media (max-width: 768px), (max-height: 625px) {
    .button {
        font-size: 16px;
        width: 240px;
    }
}