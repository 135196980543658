.item {
    cursor: pointer;
    width: 400px;
    max-width: 100%;
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 20px;
    border-radius: 20px;
    transition: .3s;

    &:hover {
        background: rgba(1, 193, 254, 0.4);
    }
    &:active {
        background: rgba(0, 94, 123, 0.4);
    }
}


@media (max-width: 1040px) {
    .item {
        width: 300px;
    }
}

@media (max-width: 768px) {
    .item {
        width: 100%;
        min-height: 81px;
        padding: 8px;
        gap: 8px;
        background-color: rgba(1, 193, 254, 0.40);
    }
}

.item.inDev {
    cursor: default;
    &:hover {
        background: none;
        backdrop-filter: none;
    }
    &:active {
        background: none;
    }
}

@media(max-width: 768px) {
    .item.inDev {
        &:hover {
            background-color: rgba(1, 193, 254, 0.40);;
        }
        &:active {
            background-color: rgba(1, 193, 254, 0.40);
        }
        
    }
      
}

.icon img {
    width: 100px;
}

@media(max-width: 768px) {
    .icon img {
        width: 44px;
    }    
}

.name {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
}

@media(max-width: 768px) {
    .name {
        font-size: 16px;
    }    
}

.dev {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
}

@media(max-width: 768px) {
    .dev {
        font-size: 12px;
    }    
}