.user_container {
    cursor: pointer;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    position: relative;
    user-select: none;
}

.icons_container {
    
    svg {
        cursor: pointer;
        transition: .3s;
    }


    &:hover svg {
        color: #36B6FB;
        fill: #36B6FB;
    }

    &:active svg {
        color: #005E7B;
        fill: #005E7B;
    }
}

.activities {
    border-radius: 8px;
    position: absolute;
    top: 130%;
    right: 0;
    background: #172E45;
    box-shadow: 0px 4px 8px rgba(147, 147, 147, 0.15);
    font-size: 16px;
    
    .lan_item {
        &:first-child {
            border-radius: 8px 8px 0 0;
        }

        &:last-child {
            border-radius: 0 0 8px 8px;
        }

        background: rgba(101, 201, 255, 0.4);
        backdrop-filter: blur(10px);
    }

    div {
        padding: 10px 15px;
        min-width: 170px;
        text-align: center;

        &:hover {
            color: #36B6FB;
        }
    }
}