html,
body {
	box-sizing: border-box;
	font-family: 'Inter', sans-serif;
	background-color: #000000;
	font-weight: 400;
	min-height: 100vh;
	min-width: 100vw;
	font-size: 100%;
	line-height: 1;
	font-size: 14px;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	font-display: swap;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent; 
}


/*Обнуление*/
* {
	padding: 0;
	margin: 0;
	border: 0;
}

*,
*:before,
*:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

:focus,
:active {
	outline: none;
}

a:focus,
a:active {
	outline: none;
}

nav,
footer,
header,
aside {
	display: block;
}


body {
	background:  #000000;
	overflow-x: hidden;
}
input,
button,
textarea {
	font-family: inherit;
}

input::-ms-clear {
	display: none;
}

button {
	cursor: pointer;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

a,
a:visited {
	color: inherit;
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}

ul li {
	list-style: none;
}

img {
	vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: inherit;
	font-weight: 400;
}

#detach-button-host {
	display: none;
}

h1 {
	font-family: 'Alef', sans-serif;
	font-weight: 700;
	font-size: 40px;
	line-height: 54px;
	text-align: center;
	font-display: swap;
}
