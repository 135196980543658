.wrapper {
    background: url('/assets/bg.png') 0 0/contain no-repeat
}
@media (max-width: 768px) {
    .wrapper {
        background: url('/assets/bg.png') 0 0/cover no-repeat
    }
}
.bots_container {
    max-width: 1300px;
    margin: 0 auto;
    padding-top: 145px;
    color: #FFFFFF;


}

@media (max-width: 1340px) {
    .bots_container {
        width: 100%;
        padding: 100px 15px 0;
    }
}

.bots_content {
    margin-top: 40px;
    padding: 40px 130px;
    background: rgba(101, 201, 255, 0.2);
    border-radius: 20px;
    backdrop-filter: blur(3px);
}

@media (max-width: 1340px) {
    .bots_content {
        padding: 40px 25px;
    }
}

@media (max-width: 768px) {
    .bots_content {
        background: transparent;
        margin-top: 25px;
        padding: 0px 0 40px;
        h1 {
            font-size: 24px;
            line-height: normal;
        }
    }
}

.items {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    gap: 40px;
    flex-wrap: wrap;
}

@media (max-width: 1340px) {
    .items {
        margin-top: 50px;
        justify-content: center;
        gap: 15px;
    }
}

@media (max-width: 768px) {
    .items {
        margin-top: 20px;
        justify-content: center;
        a {
            //flex: 1 1 40%;
            width: 100%;
        }
    }
}