.languages_container {
    cursor: pointer;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    position: relative;
    user-select: none;
}

.selected_language {
    display: flex;
    gap: 10px;
    align-items: center;

    svg {
        position: relative;
        top: -1px;
        transition: .3s;
    }


    &:hover,
    &:hover svg {
        color: #36B6FB;
        fill: #36B6FB;
    }

    &:active,
    &:active svg {
        color: #005E7B;
        fill: #005E7B;
    }
}

@media (max-width:768px) {
    .selected_language {
        &:hover,
        &:hover svg {
            color: #FFFFFF;
            fill: #FFFFFF;
        }

        &:active,
        &:active svg {
            color: #005E7B;
            fill: #005E7B;
        }
    }
}

.languages {
    border-radius: 8px;
    position: absolute;
    top: 130%;
    right: 0;
    background: #172E45;
    box-shadow: 0px 4px 8px rgba(147, 147, 147, 0.15);

    .lan_item {
        &:first-child {
            border-radius: 8px 8px 0 0;
        }

        &:last-child {
            border-radius: 0 0 8px 8px;
        }

        background: rgba(101, 201, 255, 0.4);
        backdrop-filter: blur(10px);
    }

    div {
        padding: 10px 25px;
        min-width: 90px;
        text-align: center;

        &:hover {
            color: #36B6FB;
        }
    }
}