.sign {
    max-width: 70vw;   
    max-height: 100vh;
    width: 400px;
    position: relative;
}
.cross_container {
    position: absolute;
    top: -20px;
    right: -20px;
}

.title {
    text-align: center;
    font-size: 40px;
    font-weight: 700;
}

.google_button {
    cursor: pointer;
    margin: 40px 0;
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
    padding: 16px;
    border-radius: 16px;
    font-size: 20px;
    border: 1px solid #C9C9C9;
    background: rgba(255, 255, 255, 0.20);
    transition: .2s;
    &:hover {
        background: rgba(1, 193, 254, 0.40);
    }
    &:active {
        background: rgba(0, 94, 123, 0.40);
    }
}

.form {
    margin-top: 30px;
}
.input_container {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    input {
        padding: 16px;
        border-radius: 16px;
        font-size: 20px;
    }
}

.forgot_password {
    margin-top: 30px;
    text-align: right;
    color: #36B6FB;
    font-size: 16px;
    span {
        cursor: pointer;
        &:hover {
            color: #14A1ED;
        }
        &:active {
            color: #005E7B;
        }
    }
}

.submit_button {
    margin-top: 30px;
    width: 100%;
    padding: 16px;
    border-radius: 16px;
    font-size: 20px;
    background: #36B6FB;
    color: #FFFFFF;
    text-align: center;
    &:hover {
        background: #14A1ED;
    }
    &:active {
        background: #005E7B;
    }
}

.signup {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 15px;
}

.up_link {
    cursor: pointer;
    color: #36B6FB;
    &:hover {
        color: #14A1ED;
    }
    &:active {
        color: #005E7B;
    }
}

.error {
    color: #FF6712;
    display: flex;
    gap: 5px;
    align-items: center;
    
    svg {
        fill: #FF6712;
        height: 20px;
        width: 20px;
    }
}