.loader {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('/assets/bg.png') 0 0/cover no-repeat;

    svg {
        width: 80px;
        height: 80px;
    }
}