.footer_container {
    padding: 50px 0;
    border-top: 1px solid #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
}

@media(max-width: 768px){
    .footer_container {
        font-size: 16px;
    }
}

.items {
    display: flex;
    gap: 60px;
    span, a {
        cursor: pointer;
        transition: .3s;
    }
    span:hover, a:hover {
        color: #36B6FB;
    }
    span:active, a:active {
        color: #005E7B;
    }
}

@media(max-width: 768px){
    .items {
        flex-direction: column;
        gap: 20px;
        align-items: center;
    }
}

.rights {
    padding-top: 50px;
}