.select_container {
    position: absolute;
    right: 0;
    top: 55%;
    transform: translateY(-50%);
    font-size: 20px;
    user-select: none;
}

@media (max-width: 1000px) {
    .select_container {
        position: static;
        margin-top: 25px;
    }
}

.select {
    cursor: pointer;
    position: relative;
    padding: 15px 40px 15px 20px;
    border-radius: 20px;
    background: rgba(101, 201, 255, 0.20);
    min-width: 290px;
    span {
       transition: .3s; 
    }
    
    svg{
        position: absolute;
        right: 15px;
        top: 45%;
        transition: .3s;
    }
    &:hover,
    &:hover svg {
        color: #36B6FB;
        fill: #36B6FB;
    }

    &:active,
    &:active svg {
        color: #005E7B;
        fill: #005E7B;
    }
}

@media (max-width: 768px) {
    .select {
        display: none;
    }
}

.arrowIsUp {
    transform: rotate(180deg);
}

.mobile_role_icon {
    position: absolute;
    right: 0;
    top: -50px;
    transform: translateY(-50%);
}

@media (min-width: 769px) {
    .mobile_role_icon {
        display: none;
    }
}

.option_body {
    position: absolute;
    top: 65px;
    width: 100%;
    max-height: 60vh;
    overflow-y: auto;
    background-color: #FFFFFF;
    border-radius: 20px;
}

@media (max-width: 768px) {
    .option_body {
        top: 0;
        max-height: 61vh;
        overflow-y: auto;
    }
}
.option {
    cursor: pointer;
    color: #000000;
    padding: 15px 20px;
    &:hover {
        background-color: #36B6FB;
        color: #FFFFFF;
    }
    &:first-child {
        border-radius: 20px 20px 0 0;
    }
    &:last-child {
        border-radius: 0 0 20px 20px;
    }
}

@media (max-width: 768px) {
    .option:hover {
        background-color: #FFFFFF;
        color: #000000;
    }
}