.icons_container {
        cursor: pointer;
        transition: .3s;


    &:hover {
        color: #36B6FB;
        fill: #36B6FB;
    }

    &:active {
        color: #005E7B;
        fill: #005E7B;
    }
}