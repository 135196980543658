.main {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
}

.main_body {
    
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 15px;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    color: #FFFFFF;
    background: rgba(1, 4, 17, 0.4);


}
.title {
    text-align: center;
    font-weight: 700;
    font-size: 64px;
    line-height: 150%;
}

@media (max-width: 768px), (max-height: 625px) {
    .title {
        font-size: 30px;
        line-height: 100%;
    }
}
.text {
    margin-top: 30px;
    max-width: 650px;
    width: 100%;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
}

@media (max-width: 768px), (max-height: 625px) {
    .text {
        font-size: 18px;
    }
}

.buttons {
    margin-top: 240px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 100px;
}
@media (max-height: 625px),  (max-width: 1100px) {
    .buttons {
        margin-top: 20px ;
        gap: 15px;
    }
}

.video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: 0;
}