.modal {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.1s;
    opacity: 0;
    pointer-events: none;
}

.modal_open {
    opacity: 1;
    pointer-events: all;
}

.modal_content {
    box-sizing: border-box;
    padding: 40px;
    border-radius: 20px;
    background-color: #172F4A;
    transform: scale(0);
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media(max-width: 768px) {
    .modal_content {
        padding: 30px
    }
}
.modal_content_open {
    transform: scale(1)
}

