.wrapper {
    background: url('/assets/bg.png') 0 0/contain no-repeat
}

.web_container {
    max-width: 1300px;
    margin: 0 auto;
    padding-top: 145px;
    color: #FFFFFF;
}

@media (max-width: 1340px) {
    .web_container {
        width: 100%;
        padding: 100px 15px 0;
    }
}

@media (max-width: 768px) {
    .web_container {
        padding: 70px 15px 0;
    }
}

.web_content {
    margin-top: 80px;
}

@media (max-width: 1340px) {
    .web_content {
        margin-top: 40px;
    }
}

@media (max-width: 768px) {
    .web_content {
        margin-top: 0px;

        h1 {
            display: none;
        }
    }
}
.title_body {
    position: relative;
    z-index: 2;
}

.notification {
    position: relative;
    margin-top: 40px;
    padding: 20px 75px;
    background: #FFFFFF33;
    border-radius: 20px;
    font-size: 20px;
}

@media (max-width: 768px) {
    .notification {
        margin-top: 10px;
        padding: 10px 40px;
        font-size: 16px;
    }
}

.ring {
    position: absolute;
    left: 45px;
    top: 50%;
    transform: translateY(-50%);
}

@media (max-width: 768px) {
    .ring {
        left: 15px;
    }
}

.cross {
    cursor: pointer;
    position: absolute;
    right: 45px;
    top: 50%;
    transform: translateY(-50%);
    transition: .3s;
    &:hover,
    &:hover svg {
        color: #36B6FB;
        fill: #36B6FB;
    }

    &:active,
    &:active svg {
        color: #005E7B;
        fill: #005E7B;
    }
}

@media (max-width: 768px) {
    .cross {
        right: 15px;
    }
}

.message_area {
    min-height: 30vh;
    margin-top: 60px;
    max-height: 60vh;
    overflow-y: auto;
    padding: 40px;
    border-radius: 20px;
    background: rgba(101, 201, 255, 0.20);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    

    &::-webkit-scrollbar {
        margin-right: 100px;
        padding: 50px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 20px;
        background: rgba(255, 255, 255, 0.20)
    }

    &::-webkit-scrollbar-thumb {
        border: 1px solid rgba(255, 255, 255, 0.20);
        background-color: #36B6FB;
        border-radius: 20px;
    }
}

@media (max-width: 1000px) {
    .message_area {
        margin-top: 0;
    }
}

@media (max-width: 768px) {
    .message_area {
        margin-top: 25px;
        padding: 15px;

        h1 {
            display: none;
        }
    }
}

.answer {
    align-self: flex-start;
    max-width: 45%;
    padding: 20px;
    color: #010411;
    font-size: 20px;
    line-height: 140%;
    border-radius: 0px 20px 20px 20px;
    background: #FFFFFF;

    &:not(:first-child) {
        margin-top: 20px;
    }
}

@media (max-width: 768px) {
    .answer {
        max-width: 60%;
        font-size: 16px;
        padding: 12px;
        border-radius: 0px 12px 12px 12px;
    }
}

.error_message {
    margin-top: 20px;
    color: #FF6712;
    font-size: 20px;
    font-weight: 500;
    line-height: 140%;
    display: flex;
    align-items: center;
    gap: 5px;

    svg {
        min-width: 24px;
    }
}


.loading_message {
    margin-top: 20px;
    align-self: flex-start;
    display: flex;
    gap: 2px;
    max-width: 45%;
    padding: 20px;
    color: #010411;
    font-size: 20px;
    line-height: 140%;
    border-radius: 0px 20px 20px 20px;
    background: #FFFFFF;

    div:nth-child(1) {
        animation: jump .5s ease-in-out infinite 0s;
        //transform: translateY(-10px);
    }

    div:nth-child(2) {
        animation: jump .5s ease-in-out infinite .2s;
    }

    div:nth-child(3) {
        animation: jump .5s ease-in-out infinite .3s;
    }
}

@media (max-width: 768px) {
    .loading_message {
        max-width: 80%;
        font-size: 16px;
        padding: 12px;
        border-radius: 0px 12px 12px 12px;
    }
}

@keyframes jump {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}

.mistake_message {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #9b9b9b;
    svg {
        fill: #9b9b9b;
    }
}
