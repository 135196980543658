.delete {
    max-width: 70vw;   
    max-height: 100vh;
    width: 400px;
}

.icon {
    text-align: center;
}

.title {
    margin-top: 15px;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
}

.text {
    margin-top: 15px;
    text-align: center;
    line-height: 20px;
}

.buttons {
    display: flex;
    gap: 15px;
}
.submit_button {
    margin-top: 30px;
    width: 100%;
    padding: 16px;
    border-radius: 16px;
    font-size: 20px;
    background: #36B6FB;
    color: #FFFFFF;
    text-align: center;
    &:hover {
        background: #14A1ED;
    }
    &:active {
        background: #005E7B;
    }
}

.grey {
    background: rgba(255, 255, 255, 0.2);
    &:hover {
        background: #FFFFFF;
        color: #000000;
    }
    &:active {
        background: #FFFFFF;
        color: #000000;
    }
}

.error {
    margin-top: 10px;
    color: #FF6712;
    display: flex;
    font-size: 15px;
    line-height: 15px;
    gap: 5px;
    align-items: center;
    
    svg {
        flex: 0 0 auto;
        fill: #FF6712;
        height: 20px;
        width: 20px;
    }
}