.answer {
    align-self: flex-start;
    max-width: 45%;
    padding: 20px;
    color: #010411;
    font-size: 20px;
    line-height: 140%;
    border-radius: 0px 20px 20px 20px;
    background: #FFFFFF;
    &:not(:first-child) {
        margin-top: 20px;
    }
}

@media (max-width: 768px) {
    .answer {
        max-width: 80%;
        font-size: 16px;
        padding: 12px;
        border-radius: 0px 12px 12px 12px;
    }
}

.question {
    margin-top: 20px;
    align-self: end;
    max-width: 45%;
    padding: 20px;
    color: #010411;
    font-size: 20px;
    font-weight: 500;
    line-height: 140%;
    border-radius: 20px 20px 0px 20px;
    background: #65C9FF;
}

@media (max-width: 768px) {
    .question {
        max-width: 80%;
        font-size: 16px;
        padding: 12px;
        border-radius: 12px 12px 0px 12px;
    }
}

.audio_container {
    margin-top: 20px;
    align-self: end;
    max-width: 45%;
}

@media (max-width: 768px) {
    .audio_container {
        max-width: 100%;
    }
}

audio::-webkit-media-controls-panel  {
    background-color: #65C9FF;
}
.bottom_bar {
    margin-top: 20px;
    padding: 20px 0 10px;
    border-top: 1px solid #010411;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    svg {
        cursor: pointer;
        &:hover {
            fill: #14A1ED;
        }
    
        &:active {
            fill: #005E7B;
        }
    }
}