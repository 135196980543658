.message_container {
    background: url('/assets/bg.png') 0 0/cover no-repeat;
    width: 100vw;
    height: 100vh;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 15px;
    color: #FFFFFF;
}
.button button{
    text-transform: none;
    font-size: 15px;
    height: 60px;
    width: 200px;
}