.platform_container {
    margin: 140px 0 170px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 1340px) {
    .platform_container {
        margin: 50px 0 70px;
    }
}

@media (max-width: 768px) {
    .platform_container {
        margin: 20px 0 50px;

        h1 {
            font-size: 24px;
            line-height: normal;
        }
    }
}

.platforms {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-top: 100px;
}

@media (max-width: 1340px) {
    .platforms {
        margin-top: 50px
    }
}

@media (max-width: 768px) {
    .platforms {
        margin-top: 20px;
        gap: 15px;
    }
}

.item {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 20px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    width: 400px;
    padding: 20px;
    border-radius: 20px;
    transition: .3s;

    &:hover {
        background: rgba(1, 193, 254, 0.4);
        backdrop-filter: blur(10px);
    }

    &:active {
        background: rgba(0, 94, 123, 0.4);
    }

}

@media (max-width: 768px) {
    .item {
        flex-direction: column;
        gap: 8px;
        font-size: 16px;
        line-height: normal;
        text-align: center;
        border-radius: 12px;
        background: rgba(101, 201, 255, 0.20);
    }
}