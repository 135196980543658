.wrapper {
    background:  url('/assets/bg.png') 0 0/contain no-repeat
}

@media(max-width: 768px){
    .wrapper {
        background:  url('/assets/bg.png') 0 0/cover no-repeat
    }  
}

.mobile_container {
    max-width: 1300px;
    margin: 0 auto;
    padding-top: 145px;
    color: #FFFFFF;
}
@media (max-width: 1340px) {
    .mobile_container {
        width: 100%;
        padding: 100px 15px 0;
        overflow-x: hidden;
    }
}
.content {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

@media(max-width: 768px){
    .content {
        flex-direction: column;
        text-align: center;
        gap: 25px;
    }  
}

.title {
    flex: 0 1 40%;

    h1 {
        text-align: left;
        margin-bottom: 40px;
    }

    span {        
        color: #36B6FB;
        font-size: 24px;
        font-weight: 500;
        line-height: 140%;
    }
}

@media(max-width: 768px){
    .title {
        h1 {
            margin: 25px 0 10px;
            text-align: center;
            font-size: 24px;
            line-height: normal;
        }
        span {
            font-size: 16px;
        }
    }  
}

.img {
    flex: 1 1 50%;
    text-align: center;
    display: inline-flex;
    justify-content: center;

    .pic {
        position: relative;
    }

    .pic::before {
        content: "";
        position: absolute;
        top: 10%;
        left: -70%;
        width: 651px;
        height: 408px;
        background: rgba(101, 201, 255, 0.15);
        border-radius: 50%;
        transform: rotate(-45deg);
        z-index: -1;
    }

    .pic::after {
        content: "";
        position: absolute;
        top: 10%;
        left: -70%;
        width: 651px;
        height: 408px;
        background: rgba(101, 201, 255, 0.15);
        border-radius: 50%;
        transform: rotate(-15deg);
        z-index: -1;
    }
}

.button_container {
    display: flex;
    gap: 5px;
    justify-content: center;
}

@media(max-width: 900px) {
    .button_container {
        flex-wrap: wrap;
    }
}