.wrapper {
    background: url('/assets/bg.png') 0 0/cover no-repeat
}

.contacts_container {
    max-width: 1300px;
    margin: 0 auto;
    padding-top: 145px;
    color: #FFFFFF;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

@media(max-width:1340px) {
    .contacts_container {
        padding: 120px 25px 0;
    }
}

@media(max-width:768px) {
    .contacts_container {
        padding: 100px 15px 0;
    }
}

.contacts_content {
    margin: 60px 0 40px;
    flex: 1 0 auto;
}

@media(max-width:768px) {
    .contacts_content {
        margin-top: 25px;
    }
}

.title {
    text-align: center;
    font-size: 40px;
    font-weight: 700;
}

@media(max-width:768px) {
    .title {
        display: none;
    }
}

.text {
    margin-top: 30px;
    text-align: center;
}

@media(max-width:768px) {
    .text {
        margin-top: 0;
        text-align: left;
    }
}

.text_item {
    margin-bottom: 25px;
    font-size: 20px;
    line-height: 150%;
}

@media(max-width:768px) {
    .text_item {
        font-size: 16px;
    }
}

.information_box {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 20px;
    line-height: 150%;
    a {
        color: #36B6FB;
    }
}
@media(max-width:768px) {
    .information_box {
        margin-top: 24px;
        font-size: 16px;
    }
}
.box_title {
    font-size: 20px;
}
