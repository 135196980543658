.container {
    position: absolute;
    width: 100%;
    z-index: 20;   
}

.header {
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFFFFF;
    padding: 30px 0;
}

@media (max-width: 1340px) {
    .header {
        width: 100%;
        padding: 15px;
    }
}
.logo {
    cursor: pointer;
}

.icons_container {
    display: flex;
    align-items: center;
    gap: 10px;
}
