.wrapper {
    background: url('/assets/bg.png') 0 0/cover no-repeat
}

.about_container {
    max-width: 1300px;
    margin: 0 auto;
    padding-top: 145px;
    color: #FFFFFF;
}

@media(max-width:1340px) {
    .about_container {
        padding: 120px 25px 0;
    }
}

@media(max-width:768px) {
    .about_container {
        padding: 100px 15px 0;
    }
}

.about_content {
    margin-top: 60px;
}

@media(max-width:768px) {
    .about_content {
        margin-top: 25px;
    }
}

.title {
    text-align: center;
    font-size: 40px;
    font-weight: 700;
}

@media(max-width:768px) {
    .title {
        display: none;
    }
}

.text {
    margin-top: 30px;
}

@media(max-width:768px) {
    .text {
        margin-top: 0;
    }
}

.text_item {
    font-family: Inter;
    margin-bottom: 25px;
    font-size: 20px;
    line-height: 150%;

    a {
        transition: .3s;
    }

    a:hover {
        color: #36B6FB;
    }

    a:active {
        color: #005E7B;
    }
    &:first-child {
        text-align: center;
    }
}

@media(max-width:768px) {
    .text_item {
        font-size: 16px;
    }
}
