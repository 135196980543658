.email {
    max-width: 70vw;   
    max-height: 100vh;
    width: 400px;
}

.icon {
    text-align: center;
}

.title {
    margin-top: 15px;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
}

.text {
    margin-top: 15px;
    text-align: center;
}

.submit_button {
    margin-top: 30px;
    width: 100%;
    padding: 16px;
    border-radius: 16px;
    font-size: 20px;
    background: #36B6FB;
    color: #FFFFFF;
    text-align: center;
    &:hover {
        background: #14A1ED;
    }
    &:active {
        background: #005E7B;
    }
}