.navigation {
  display: flex;
  gap: 10px;
  font-size: 16px;
  line-height: 150%;
}

@media (max-width: 768px) {
  .navigation {
    position: relative;
    justify-content: center;
    box-sizing: border-box;
    width: 100vw;
    margin-left: -15px;
    padding: 15px 0;
    background: rgba(101, 201, 255, 0.20);
  }
}

.main_link {
  box-sizing: border-box;
  border-bottom: 1px solid transparent;
  cursor: pointer;

  &:hover {
    border-bottom: 1px solid #FFF;
  }
}

@media (max-width: 768px) {
  .main_link,
  .slash,
  .inviz_name {
    display: none;
  }
}

.bots_role {
  display: none;
}
@media (max-width: 768px) {
  .bots_role {
    display: block;
    font-weight: 600;
  }
}

.arrow {
  display: none;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
}

@media (max-width: 768px) {
  .arrow {
    display: block;
  }
}

